import React, { useEffect, useReducer, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Creators as ProductListCreators } from "store/ducks/posto/list/postosList";
import { Creators as PlaceListCreators } from "store/ducks/place/list/placesList";
import { useDispatch, useSelector } from "react-redux";
import CustomizedInputBase from "components/InputSearchBase";
import Navbar from "layout/Navbar";
import SelectBase from "components/SelectBase";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import {
  AppBar,
  BottomNavigation,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Slider,
  Tab,
  Tabs,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
} from "@mui/material";
import PostosList from "./components/PostosList";
import { Close, Image, Room, Route } from "@mui/icons-material";
import HereMap from "components/Heremap";
import ImgNoPermissionGps from "images/no_permisison_location.webp";
import { yellow } from '@mui/material/colors';
import { grey } from "@mui/material/colors";
import LogoGoodCard from "images/logo_good_card.png";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [localState, setLocalState] = React.useState({
    distancia: 8,
    combustivel: "etanol_comum",
    myVar: 0,
  });
  const [checkedAtualizados, setAtualizados] = React.useState(true);
  const [checkedGoodCard, setGoodCard] = React.useState(false);
  const handleChangeAtualizados = (event) => {
    setAtualizados(event.target.checked);
  };

  const handleChangeGoodCard = (event) => {
    setGoodCard(event.target.checked);
  };

  const selectedCity = localStorage.getItem("selectedCity") || "Goiânia";

  const [location, setLocation] = React.useState({
    lat: 'nf',
    lng: 'nf',
    cidade: selectedCity,
  });

  const [coordsRoute, setCoordsRoute] = React.useState([]);

  const { lat, lng } = location;

  const [permissionLocation, setPermissionLocation] = useState(false);

  const showPosition = (position) => {
    setLocation({
      ...location,
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });
    if (!permissionLocation) {
      setPermissionLocation(true);
    }
  };

  const setCity = (newCity) => {
    setLocation({
      ...location,
      cidade: newCity,
    });
    localStorage.setItem("selectedCity", newCity);
  };

  const changeDaniedPermission = () => {
    if (permissionLocation === true) {
      setPermissionLocation(false);
    }
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        changeDaniedPermission();
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Localização indisponível.");
        break;
      case error.TIMEOUT:
        alert("A requisição expirou.");
        break;
      case error.UNKNOWN_ERROR:
        alert("Algum erro desconhecido aconteceu.");
        break;
    }
  };

  function getLocation() {
    if (navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      // eslint-disable-next-line no-undef
      x.innerHTML = "O seu navegador não suporta Geolocalização.";
    }
  }

  // verificar watchPosition no HTTPS

  var refreshingLocation = useRef(null);
  var waitingPermission = useRef(null);

  function refreshSetInterval() {
    if (!permissionLocation) {
      clearInterval(refreshingLocation.current);
      clearInterval(waitingPermission.current);
      waitingPermission.current = setInterval(getLocation, 1000);
    } else {
      clearInterval(waitingPermission.current);
      clearInterval(refreshingLocation.current);
      refreshingLocation.current = setInterval(getLocation, 60000);
    }
  }

  React.useEffect(() => {
    getLocation();
    refreshSetInterval();
    if (permissionLocation) {
      if (userCoords.lat === 0 && userCoords.lng === 0) {
        setUserCoords({ lat: location.lat, lng: location.lng });
      }
    }
  }, [permissionLocation]);

  React.useEffect(() => {
    if (coordsRoute.length > 0) {
      dispatch(ProductListCreators.getPostoListRouteRequest(coordsRoute));
    } else if (lat !== 0 && lng !== 0) {
      refreshSetInterval();
      dispatch(
        ProductListCreators.getPostoListRequest(
          location,
          localState.combustivel
        )
      );
    }
  }, [location, coordsRoute]);

  React.useEffect(() => {
    if (postoList.findIndex((item) => item.notShowRadius) !== -1) {
      dispatch(
        ProductListCreators.getPostoListRequest(
          location,
          localState.combustivel
        )
      );
    }
  }, [localState.combustivel]);

  const onSearchPlace = (inputSearch) => {
    dispatch(PlaceListCreators.getPlaceListRequest(inputSearch));
  };

  const { placeList, placeListLoading } = useSelector(
    (state) => state.place.list
  );

  const setFilter = (e, newVal) => {
    setLocalState({
      ...localState,
      distancia: coordsRoute.length === 0 ? newVal : 1000,
    });
    handleClick(
      `Ordenação por menor valor de ${localState.combustivel
        .replaceAll("_", " ")
        .replace("s10", "S-10")} em um raio de ${newVal} KM`
    );
  };

  const { postoList, postoListLoading } = useSelector(
    (state) => state.posto.list
  );

  const goToRouteMap = () => {
    setUserCoords({ lat: location.lat, lng: location.lng });
    setIsEnableMapsRoute(true);
  };

  const goToMain = () => {
    setIsEnableMapsRoute(false);
  };

  const onGetListByRoute = () => {
    clearInterval(waitingPermission.current);
    clearInterval(refreshingLocation.current);
    setCoordsRoute(localStorage.getItem("coordsRouteValues"));
    setIsEnableMapsRoute(false);
    setLocalState({
      ...localState,
      distancia: 1000,
    });
  };

  const removeCoords = () => {
    setLocalState({
      ...localState,
      distancia: 8,
    });
    setCoordsRoute([]);
    refreshSetInterval();
  };

  React.useEffect(() => {
    var _loaded = {};
    function addScript(url) {
      if (!_loaded[url]) {
        var s = document.createElement("script");
        s.src = url;
        s.id = "heremapscript";
        document.head.appendChild(s);
        _loaded[url] = true;
        setTimeout(() => {
          s.onload = s.onreadystatechange = function () {
            if (!this.readyState || this.readyState === "loaded") {
              s.onload = s.onreadystatechange = null;
              document.head.removeChild(s);
            }
          };
        }, 2000);
      }
    }
    addScript("js/heremap.js");
  }, []);

  React.useEffect(() => {
    return () => {
      var script = document.getElementById("heremapscript");
      if (script != null) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#DADADA",
      },
      secondary: {
        main: "#2979ff",
      },
    },
  });

  const [messageAlert, setMessageAlert] = React.useState("");

  const [open, setOpen] = React.useState(false);

  const handleClick = (msg) => {
    setOpen(true);
    setMessageAlert(msg);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [isEnableMapsRoute, setIsEnableMapsRoute] = React.useState(false);

  const [valueTabs, setValueTab] = React.useState("etanol_comum");

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    setLocalState({
      ...localState,
      combustivel: newValue,
    });
    handleClick(
      `Ordenação por menor valor de ${newValue
        .replaceAll("_", " ")
        .replace("s10", "S-10")} ${
        coordsRoute.length !== 0
          ? `na rota selecionada`
          : permissionLocation
          ? `em um raio de ${localState.distancia} KM`
          : `em ${location.cidade}`
      }`
    );
  };

  const cities = [
    {
      displayName: "Goiânia",
      value: "Goiânia",
    },
    {
      displayName: "Anápolis",
      value: "Anápolis",
    },
    {
      displayName: "Trindade",
      value: "Trindade",
    },
    {
      displayName: "Aparecida de Goiânia",
      value: "Aparecida de Goiânia",
    },
    {
      displayName: "Senador Canedo",
      value: "Senador Canedo",
    },
    {
      displayName: "Abadia de Goiás",
      value: "Abadia de Goiás",
    },
    {
      displayName: "Caldas Novas",
      value: "Caldas Novas",
    },
  ];

  const [destinyDetails, setDestinyDetails] = React.useState(null);

  const [userCoords, setUserCoords] = React.useState({ lat: 0, lng: 0 });

  const newDestinyMap = (newCoords) => {
    //console.log(window)
    //window.self.newMarker({ lat: newCoords.lat, lng: newCoords.lng })
    setDestinyDetails(newCoords);
  };

  const statusMobile = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      return true
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true
    }

    return false;
  }

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.open('intent:https://postosgo.com.br#Intent;end', '_blank');
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.open('x-safari-https://postosgo.com.br', '_blank');
    }

    return "unknown";
  }

  const [searchParams] = useSearchParams();

  return (
    <div>
      <Navbar />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: !isEnableMapsRoute ? "" : "none",
        }}
      >
        <Tabs
          value={valueTabs}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Gasolina" value="gasolina_comum" />
          <Tab label="Etanol" value="etanol_comum" />
          <Tab label="Diesel" value="diesel_comum" />
          <Tab label="Diesel S-10" value="diesel_s10_comum" />
        </Tabs>
      </Box>
      <Box sx={{ backgroundColor: yellow['700'], textAlign: 'center', padding: '10px', color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
        No momento o governo só está fornecendo apenas 40% dos preços dos postos, por isso confirme o selo de atualizado em cada posto. Última atualização fornecida: 12/01/2025
      </Box>
      <Box
        sx={{
          alignItems: "center",
          paddingRight: "15px",
          display:
            !isEnableMapsRoute && coordsRoute.length === 0 ? "flex" : "none",
        }}
      >
        <SelectBase
          sx={{ flex: 1, paddingTop: "10px", px: "20px" }}
          options={cities}
          onChange={setCity}
          value={location.cidade}
          label="Cidade"
        />
        <Route
          sx={{ color: permissionLocation ? grey["900"] : grey["400"] }}
          onClick={permissionLocation ? goToRouteMap : () => {}}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          display:
            !isEnableMapsRoute ? "flex" : "none",
        }}
      >
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              checked={checkedAtualizados}
              onChange={handleChangeAtualizados}
            />
          }
          label="Filtrar apenas por Postos Atualizados"
          labelPlacement="end"
          sx={{ pl: "20px" }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          display:
            !isEnableMapsRoute ? "flex" : "none",
        }}
      >
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              checked={checkedGoodCard}
              onChange={handleChangeGoodCard}
            />
          }
          label="Filtrar apenas por Good Card"
          labelPlacement="end"
          sx={{ pl: "20px" }}
        />
        <img src={LogoGoodCard} width={"30px"} />
      </Box>
      {isEnableMapsRoute && (
        <CustomizedInputBase
          options={placeList}
          onClick={newDestinyMap}
          onChange={onSearchPlace}
        />
      )}
      <Box
        sx={{
          visibility: isEnableMapsRoute ? "visible" : "hidden",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        {permissionLocation && (
          <HereMap
            userPosition={userCoords}
            permission={permissionLocation}
            destinyPosition={destinyDetails}
          />
        )}
        <Box>
          <ThemeProvider theme={darkTheme}>
            <AppBar
              sx={{ display: "flex", top: "auto", height: "11.2vh", bottom: 0 }}
            >
              <Toolbar sx={{ height: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-between",
                  }}
                >
                  <Button color="secondary" onClick={goToMain}>
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={onGetListByRoute}
                  >
                    Concluir
                  </Button>
                </Box>
              </Toolbar>
              <Box sx={{ background: "#1976d2", height: "6vh" }} />
            </AppBar>
          </ThemeProvider>
        </Box>
      </Box>
      {!isEnableMapsRoute && (
        <div className="row">
          <Box
            sx={{
              display: !permissionLocation ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "15px",
              backgroundColor: "rgba(61, 90, 254, 0.1)",
              padding: "10px",
              borderRadius: "10px",
              mx: "20px",
            }}
          >
            {/*
            <Box
              sx={{
                width: { md: "100%", lg: "25%" },
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={ImgNoPermissionGps} width={"80%"} />
            </Box>
            */}
            {statusMobile() && searchParams.get("instagram") ?
            <Typography
            sx={{
              textAlign: "center",
              fontSize: "12px",
              lineHeight: "1.4",
              textTransform: "uppercase",
              color: "#3d5afe",
            }}
          >
            Por Favor, para ver postos próximo a você abra este site no seu navegador
            <br/><br />
            <Button variant="contained" size="small" color="success" onClick={getMobileOperatingSystem}>clicando aqui</Button>
          </Typography>
          : <Typography
              sx={{
                textAlign: "center",
                fontSize: "12px",
                lineHeight: "1.4",
                textTransform: "uppercase",
                color: "#3d5afe",
              }}
            >
              Por Favor, habilite o GPS do navegador <br />
              para ver postos próximo a você
            </Typography>
          }
          </Box>
          <PostosList
            list={postoList}
            loading={postoListLoading}
            location={location}
            filter={localState}
            destinyDetails={destinyDetails}
            filterByAtualizados={checkedAtualizados}
            filterByGoodCard={checkedGoodCard}
          />
        </div>
      )}
      <Box
        sx={{
          visibility:
            !isEnableMapsRoute &&
            coordsRoute.length === 0 &&
            postoList.findIndex((item) => item.notShowRadius) === -1
              ? "visible"
              : "hidden",
          bottom: 0,
          position: "fixed",
          zIndex: "99999",
          width: "100%",
          height: "70px",
        }}
      >
        <ThemeProvider theme={darkTheme}>
          <AppBar position="fixed" sx={{ top: "auto", bottom: 0 }}>
            <Toolbar>
              <Box
                display="flex"
                sx={{
                  width: "100%",
                  px: 4,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    pr: 4,
                  }}
                >
                  <Slider
                    color="secondary"
                    value={localState.distancia}
                    onChange={setFilter}
                    min={1}
                    max={20}
                  />
                </Box>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                  }}
                >
                  {localState.distancia} KM
                </div>
              </Box>
            </Toolbar>
            <Box sx={{ background: "#1976d2", height: "4vh" }} />
          </AppBar>
        </ThemeProvider>
      </Box>
      {!isEnableMapsRoute && coordsRoute.length > 0 && (
        <Box
          sx={{
            bottom: 0,
            position: "fixed",
            zIndex: "99999999",
            width: "100%",
            height: "auto",
          }}
        >
          <ThemeProvider theme={darkTheme}>
            <AppBar position="fixed" sx={{ top: "auto", bottom: 0 }}>
              <Toolbar>
                <Box
                  display="flex"
                  sx={{
                    width: "100%",
                    py: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        paddingLeft: "13px",
                        opacity: 0.7,
                      }}
                    >
                      Postos com base no destino selecionado
                    </Typography>
                    {destinyDetails && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "2px",
                        }}
                      >
                        <Room sx={{ fontSize: "14px" }} />
                        <Typography sx={{ fontSize: "12px" }}>
                          {destinyDetails.name ? destinyDetails.name : ``}
                          {destinyDetails.address.road
                            ? `, ${destinyDetails.address.road}`
                            : ``}
                          {destinyDetails.address.suburb
                            ? `, ${destinyDetails.address.suburb}`
                            : ``}
                          {destinyDetails.address.city
                            ? `, ${destinyDetails.address.city}`
                            : ``}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Close onClick={removeCoords} />
                </Box>
              </Toolbar>
              <Box sx={{ background: "#1976d2", height: "4vh" }} />
            </AppBar>
          </ThemeProvider>
        </Box>
      )}
      <Box sx={{ position: "absolute", zIndex: "99999999" }}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            sx={{ bottom: "80px" }}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
              {messageAlert}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
    </div>
  );
};

export default Home;
